/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import classNames from 'classnames';
import {OldProductThumbnail} from '@wix/wixstores-client-common-components/dist/es/src/OldProductThumbnail/OldProductThumbnail';
import {IMediaItem, MediaItemRequiredDimensions} from '@wix/wixstores-client-core/dist/src/types/media-item';
import * as imageSDK from 'image-client-api/dist/imageClientSDK';
import autobind from 'auto-bind-es5';
import s from './ProductImage.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct} from '../../../../types/galleryTypes';

export interface IProductImageProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageRatioId: number;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  upscaleMethod?: 'auto';
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
  isAllowGalleryRoundCornersInViewer: boolean;
}

interface IProductImageState {
  shouldShowSsrImagePlaceholder: boolean;
  imageContainerDimensions: {width: number; height: number};
  inBrowser: boolean;
}

export enum DataHook {
  Images = 'product-item-images',
  PrimaryImage = 'product-item-primary-image',
  SecondaryImage = 'product-item-secondary-image',
}

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

const ZOOM_COEFFICIENT = 1.3;

export class ProductImageComponent extends React.Component<IProductImageProps, IProductImageState> {
  public static defaultProps: Partial<IProductImageProps> = {
    upscaleMethod: 'auto',
  };

  constructor(props) {
    super(props);

    autobind(this);
  }

  private readonly imageContainerRef = React.createRef<HTMLDivElement>();
  public state: IProductImageState = {
    shouldShowSsrImagePlaceholder: true,
    imageContainerDimensions: {width: 0, height: 0},
    inBrowser: false,
  };

  public componentDidMount(): void {
    this.setState({
      inBrowser: true,
    });
    this.updateImageContainerDimensions();
  }

  public componentDidUpdate(prevProps: Readonly<IProductImageProps>): void {
    const {imageRatioId: prevImageRatioId} = prevProps;
    const {imageRatioId: currentImageRatioId} = this.props;

    if (prevImageRatioId !== currentImageRatioId) {
      this.updateImageContainerDimensions();
    }
  }

  public render() {
    const {imageRatioId} = this.props;
    const {isAllowGalleryRoundCornersInViewer} = this.props;

    return (
      <div
        className={classNames(
          s.productImages,
          {[s.roundCorners]: isAllowGalleryRoundCornersInViewer},
          'heightByImageRatio',
          `heightByImageRatio${imageRatioId}`
        )}
        ref={this.imageContainerRef}
        style={{...this.getSsrImagePlaceholder()}}
        data-hook={DataHook.Images}>
        {this.state.inBrowser && this.renderImages()}
        {this.props.children}
      </div>
    );
  }

  private updateImageContainerDimensions() {
    const width = Math.ceil(this.imageContainerRef.current.clientWidth);
    const height = Math.ceil(this.imageContainerRef.current.clientHeight);

    this.setState({imageContainerDimensions: {width, height}});
  }

  private getResizedImageUrl(
    {url: relativeUrl, width: sourceWidth, height: sourceHeight}: IMediaItem,
    {width: targetWidth, height: targetHeight}: MediaItemRequiredDimensions,
    isSsr: boolean = false
  ): string {
    const {imageModeId, upscaleMethod} = this.props;
    const options = {
      isSEOBot: isSsr,
    };
    if (sourceWidth <= targetWidth && sourceHeight <= targetHeight && imageModeId === ImageModeId.Fit) {
      return imageSDK.getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, sourceWidth, sourceHeight, options);
    } else if (imageModeId === ImageModeId.Fit) {
      return imageSDK.getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options);
    } else {
      return imageSDK.getScaleToFillImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, {
        ...options,
        upscaleMethod,
      });
    }
  }

  private getSsrImagePlaceholder() {
    if (!this.state.shouldShowSsrImagePlaceholder) {
      return {};
    }

    const {imageModeId, imageRatioId, product} = this.props;

    if (product.media && product.media.length > 0) {
      const media = product.media[0];
      const viewPort = {width: 100, height: 100};
      let newDimensions;

      if (imageModeId === ImageModeId.Fit) {
        const minMultiplier = Math.min(viewPort.width / media.width, viewPort.height / media.height);
        newDimensions = {width: media.width * minMultiplier, height: media.height * minMultiplier};
      } else {
        const ratio = ratioIdToRatioMap[imageRatioId];
        newDimensions = {width: viewPort.width, height: viewPort.width / ratio};
      }

      return {
        backgroundImage: `url(${this.getResizedImageUrl(media, newDimensions, true)})`,
        backgroundSize: imageModeId === ImageModeId.Crop ? 'cover' : 'contain',
      };
    } else {
      return {backgroundColor: 'rgba(0,0,0,.9)'};
    }
  }

  private hideSsrPlaceholder() {
    this.setState({
      shouldShowSsrImagePlaceholder: false,
    });
  }

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
    } = this.props;
    const {shouldShowSsrImagePlaceholder} = this.state;

    const productImageWidth = this.state.imageContainerDimensions.width;
    const productImageHeight = this.state.imageContainerDimensions.height;
    const shouldRenderSecondaryImage = !isMobile && product.media.length >= 2 && hoverType === HoverType.Alternate;
    const coefficient = hoverType === HoverType.Zoom ? ZOOM_COEFFICIENT : 1;

    const imageClassNames = classNames(s.productImage, externalImageClass, {
      [s.crop]: imageModeId === ImageModeId.Crop,
      [s.fit]: imageModeId === ImageModeId.Fit,
    });

    const thumbnailClass = classNames(s.productThumbnail, externalThumbnailClass, {
      [s.hidden]: shouldShowSsrImagePlaceholder,
    });

    return (
      <>
        <OldProductThumbnail
          defaultImageClassName={s.defaultThumbnail}
          mediaItemIndex={0}
          width={productImageWidth * coefficient}
          height={productImageHeight * coefficient}
          className={thumbnailClass}
          imageClassName={imageClassNames}
          product={product}
          getResizedImageUrl={this.getResizedImageUrl}
          data-hook={DataHook.PrimaryImage}
          hasFixedSize={false}
          alwaysShowBadge={true}
          withSrcSet={false}
          onImageLoad={this.hideSsrPlaceholder}
          digitalProductBadgeTranslationKey={this.props.textsMap.digitalProductBadgeAriaLabel}
        />
        {shouldRenderSecondaryImage && (
          <OldProductThumbnail
            mediaItemIndex={1}
            width={productImageWidth}
            height={productImageHeight}
            className={thumbnailClass}
            imageClassName={imageClassNames}
            product={product}
            getResizedImageUrl={this.getResizedImageUrl}
            data-hook={DataHook.SecondaryImage}
            hasFixedSize={false}
            alwaysShowBadge={true}
            withSrcSet={false}
            digitalProductBadgeTranslationKey={this.props.textsMap.digitalProductBadgeAriaLabel}
          />
        )}
      </>
    );
  }
}

export const ProductImage = ProductImageComponent;
