/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {OldProductThumbnail} from '@wix/wixstores-client-common-components/dist/es/src/OldProductThumbnail/OldProductThumbnail';
import autobind from 'auto-bind-es5';
import s from './ProductImage.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct} from '../../../../types/galleryTypes';
import {ImageResizeOptions} from 'wix-ui-tpa';

export interface IProductImageProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageRatioId: number;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  upscaleMethod?: 'auto';
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
  isAllowGalleryRoundCornersInViewer: boolean;
}

interface IProductImageState {
  imageContainerDimensions: {width: number; height: number};
}

export enum DataHook {
  Images = 'product-item-images',
  PrimaryImage = 'product-item-primary-image',
  SecondaryImage = 'product-item-secondary-image',
}

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

const ZOOM_COEFFICIENT = 1.3;

export class ProductImageComponent extends React.Component<IProductImageProps, IProductImageState> {
  public static defaultProps: Partial<IProductImageProps> = {
    upscaleMethod: 'auto',
  };

  constructor(props) {
    super(props);

    autobind(this);
  }

  private readonly imageContainerRef = React.createRef<HTMLDivElement>();
  public state: IProductImageState = {
    imageContainerDimensions: {width: 0, height: 0},
  };

  public componentDidMount(): void {
    this.updateImageContainerDimensions();
  }

  public componentDidUpdate(prevProps: Readonly<IProductImageProps>): void {
    const {imageRatioId: prevImageRatioId} = prevProps;
    const {imageRatioId: currentImageRatioId} = this.props;

    if (prevImageRatioId !== currentImageRatioId) {
      this.updateImageContainerDimensions();
    }
  }

  public render() {
    const {imageRatioId} = this.props;
    const {isAllowGalleryRoundCornersInViewer} = this.props;

    return (
      <div
        className={classNames(
          s.productImages,
          {[s.roundCorners]: isAllowGalleryRoundCornersInViewer},
          'heightByImageRatio',
          `heightByImageRatio${imageRatioId}`
        )}
        ref={this.imageContainerRef}
        data-hook={DataHook.Images}>
        {this.renderImages()}
        {this.props.children}
      </div>
    );
  }

  private updateImageContainerDimensions() {
    const width = Math.ceil(this.imageContainerRef.current.clientWidth);
    const height = Math.ceil(this.imageContainerRef.current.clientHeight);

    this.setState({imageContainerDimensions: {width, height}});
  }

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
    } = this.props;

    const productImageWidth = this.state.imageContainerDimensions.width;
    const productImageHeight = this.state.imageContainerDimensions.height;
    const shouldRenderSecondaryImage = !isMobile && product.media.length >= 2 && hoverType === HoverType.Alternate;
    const coefficient = hoverType === HoverType.Zoom ? ZOOM_COEFFICIENT : 1;

    const imageClassNames = classNames(s.productImage, externalImageClass, {
      [s.crop]: imageModeId === ImageModeId.Crop,
      [s.fit]: imageModeId === ImageModeId.Fit,
    });

    const thumbnailClass = classNames(s.productThumbnail, externalThumbnailClass);

    return (
      <>
        <OldProductThumbnail
          defaultImageClassName={s.defaultThumbnail}
          mediaItemIndex={0}
          width={productImageWidth * coefficient}
          height={productImageHeight * coefficient}
          className={thumbnailClass}
          imageClassName={imageClassNames}
          product={product}
          data-hook={DataHook.PrimaryImage}
          hasFixedSize={false}
          alwaysShowBadge={true}
          digitalProductBadgeTranslationKey={this.props.textsMap.digitalProductBadgeAriaLabel}
          useNewUiTpaImageComponent={true}
          imageResizeOptions={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
        />
        {shouldRenderSecondaryImage && (
          <OldProductThumbnail
            mediaItemIndex={1}
            width={productImageWidth}
            height={productImageHeight}
            className={thumbnailClass}
            imageClassName={imageClassNames}
            product={product}
            data-hook={DataHook.SecondaryImage}
            hasFixedSize={false}
            alwaysShowBadge={true}
            digitalProductBadgeTranslationKey={this.props.textsMap.digitalProductBadgeAriaLabel}
            useNewUiTpaImageComponent={true}
            imageResizeOptions={
              imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain
            }
          />
        )}
      </>
    );
  }
}

export const ProductImageNew = ProductImageComponent;
